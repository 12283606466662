import { Component, Input, OnInit } from '@angular/core';
import { LookerResponse, LookerService } from '../../../../core/services/looker.service';
import { SharedModule } from "../../../../core/shared.module";
import { Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { OnDestroy } from "../../../../core/shared/rxjs-helpers";
import { Dashboard } from "../../../../store/reducers/reporting.reducer";
import { Store } from "@ngrx/store";
import { selectReport } from "../../../../store/selectors/reporting.selectors";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { filter, tap } from "rxjs/operators";
import { TitleService } from "../../../../core/services/title.service";
import { setPageHeaderContent } from 'src/app/store';
import { ProductDashboardHeaderComponent } from 'src/app/views/product/beta/product-dashboard-header/product-dashboard-header.component';

@Component({
  selector: 'app-custom-dashboard',
  templateUrl: './custom-dashboard.component.html',
  standalone: true,
  imports: [SharedModule, ProductDashboardHeaderComponent]
})
export class CustomDashboardComponent extends OnDestroy implements OnInit {
  url$: Observable<LookerResponse>;
  report$: Observable<Dashboard>;

  @Input() dashboard: string;

  constructor(private looker: LookerService,
              private store: Store,
              private titleService: TitleService,
              private activatedRoute: ActivatedRoute) {
    super()
  }

  ngOnInit() {
    this.activatedRoute.params.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(({report}) => {
      if (!report) {
        report = this.dashboard;
      }
      this.url$ = this.looker.createCustomDashboardUrl(report, this.activatedRoute.snapshot.queryParams, 'v2');
      this.report$ = this.store.select(selectReport(report)).pipe(
        filter((report) => !!report),
        tap((report) => this.setTitle(report)),
      )
    });
  }

  setTitle(dashboard: Dashboard) {
    this.titleService.setTitle(dashboard.title);
    this.store.dispatch(setPageHeaderContent({pageTitle: dashboard.title}));
  }

  onLoaded($event) {
    this.titleService.setTitle($event.dashboard.title);
  }
}
