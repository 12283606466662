<header class="px-10 pt-3.5 pb-7 z-50 sticky flex justify-between items-center bg-soft-blue dark:bg-slate-950">
    <div class="flex items-center gap-5 dark:text-white/70 dark:hover:text-white">
      <a class="z-20 block active" [routerLink]="['/products']">
        <img alt="Kohort logo" src="//kohort-media.s3.eu-west-1.amazonaws.com/control/logo-white.png" class="w-auto h-4.5 hidden dark:inline-block"/>
        <img alt="Kohort logo" src="//kohort-media.s3.eu-west-1.amazonaws.com/control/logo.png" class="w-auto h-4.5 dark:hidden"/>
      </a>
      <span class="pl-4 opacity-50">/</span>
      <div class="flex-1">
        <router-outlet name="breadcrumbs"></router-outlet>
      </div>
    </div>
    <div class="flex-1 flex justify-center">
      <nav class="flex items-center gap-4">
        <div class="flex gap-3">
            <a [routerLink]="['/products', product.id, 'reports']"
              routerLinkActive #dashRLA="routerLinkActive"
              [ngClass]="{
                'border dark:border-slate-700/70 active bg-white dark:bg-background shadow-sm': dashRLA.isActive,
                'hover:bg-white text-gray-800 dark:hover:text-gray-800': !dashRLA.isActive
              }"
              class="py-2 px-3 rounded-full text-[13px] font-medium w-[115px] text-center flex items-center justify-center gap-2 text-black hover:no-underline dark:text-white">
              <span>{{ 'app.navigation.dashboards' | translate }}</span>
            </a>
            <app-overlay-popup position="bottom-center" data-cy="top-nav-main-links" routerLinkActive #rla="routerLinkActive">
              <a [routerLink]="['/products', product.id, 'scenarios']"
                [ngClass]="{
                  'border dark:border-slate-700/70 active bg-white dark:bg-background shadow-sm': rla.isActive,
                  'hover:bg-white text-gray-800 dark:hover:text-gray-800': !rla.isActive
                }"
                class="py-2 px-3 rounded-full text-[13px] font-medium w-[115px] text-center flex items-center justify-center gap-2 text-black hover:no-underline dark:text-white">
                <span>{{ 'app.navigation.planning.title' | translate }}</span>
                <app-svg-images svgName="chevron-down" class="h-3 inline-block"></app-svg-images>
              </a>
              <div popup-content class="min-w-[200px] z-10 px-2.5 py-1 w-full inline-flex flex-col items-center justify-center subpixel-antialiased outline-none box-border text-small bg-white border rounded-lg shadow-sm mt-2">
                <div class="text-sm z-10 font-body max-h-[80vh] overflow-y-auto overflow-x-hidden grid grid-cols-5 divide-x max-w-[650px]">
                    <!-- Scenario links -->
                    <div class="col-span-3 p-2 pl-0 group/listSection">
                        <!-- Scenarios -->
                        <div class="flex items-center gap-3 pb-2 px-4">
                            <h3 class="text-gray-500 text-sm">{{ 'app.navigation.planning.scenarios.title' | translate }}</h3>
                            <app-svg-images svgName="square-3-stack-3d-solid" class="text-gray-300 h-3 group-hover/listSection:text-teal-green"></app-svg-images>
                        </div>
                        <a [routerLink]="['/products', product.id, 'scenarios']" class="group active hover:bg-soft-blue px-4 py-3 font-medium gap-16 first:rounded-t-lg last:rounded-b-lg flex items-center justify-between hover:no-underline text-black">
                            <span class="flex-1">{{ 'app.navigation.planning.scenarios.all' | translate }}</span>
                            <div class="flex items-center justify-end">
                                <app-svg-images svgName="arrow-small-right" class="h-3 opacity-10 group-hover:opacity-100"></app-svg-images>
                            </div>
                        </a>
                        <!-- Favourite scenarios -->
                        @if (favouriteScenarios$ | async; as favourites) {
                            <h3 class="text-gray-500 text-sm p-4 pb-2">{{ 'app.navigation.planning.scenarios.favorites' | translate }}</h3>
                            <div class="space-y-0.5 max-h-[350px] overflow-y-auto custom-scroll overflow-x-hidden">
                              @for (favouriteScenario of favourites; track favouriteScenario.id) {
                                <a [routerLink]="['/products', product.id, 'scenarios', favouriteScenario.id, 'milestones']" class="group active hover:bg-soft-blue px-4 py-3 font-medium gap-16 first:rounded-t-lg last:rounded-b-lg flex items-center justify-between hover:no-underline text-black">
                                    <div class="flex-1 flex items-center gap-3">
                                        <span>{{ favouriteScenario.name }}</span>
                                    </div>
                                    <div class="flex items-center justify-end gap-3">
                                        <span class="text-gray-400/70 group-hover:text-gray-500 text-xs">{{ favouriteScenario.id }}</span>
                                        <app-svg-images svgName="heart" class="h-3 text-kohort-primary"></app-svg-images>
                                        <app-svg-images svgName="arrow-small-right" class="h-3 opacity-10 group-hover:opacity-100"></app-svg-images>
                                    </div>
                                </a>
                              }
                            </div>
                        }
                    </div>
                  <!-- Product Settings -->
                  <div class="p-2 pr-0 col-span-2 group/listSection">
                    <div class="flex items-center gap-3 pb-2 px-4">
                      <h3 class="text-gray-500 text-sm">{{ 'app.navigation.planning.product.title' | translate }}</h3>
                      <app-svg-images svgName="cog-6-tooth-solid" class="text-gray-300 h-3 group-hover/listSection:text-digital-blue"></app-svg-images>
                    </div>
                    <a [routerLink]="['/products', product.id, 'settings']"  class="group active hover:bg-soft-blue px-4 py-3 font-medium gap-16 first:rounded-t-lg last:rounded-b-lg flex items-center justify-between hover:no-underline text-black">
                      <div class="flex-1 flex items-center gap-3.5">
                        <span class="flex-1">{{ 'app.navigation.planning.product.settings' | translate }}</span>
                      </div>
                      <div class="flex items-center justify-end">
                        <app-svg-images svgName="arrow-small-right" class="h-3 opacity-0 group-hover:opacity-100"></app-svg-images>
                      </div>
                    </a>
                    <a [routerLink]="['/products', product.id, 'files']"  class="group active hover:bg-soft-blue px-4 py-3 font-medium gap-16 first:rounded-t-lg last:rounded-b-lg flex items-center justify-between hover:no-underline text-black">
                      <div class="flex-1 flex items-center gap-3.5">
                        <span class="flex-1">{{ 'app.navigation.planning.product.files' | translate }}</span>
                      </div>
                      <div class="flex items-center justify-end">
                        <app-svg-images svgName="arrow-small-right" class="h-3 opacity-0 group-hover:opacity-100"></app-svg-images>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </app-overlay-popup>
        </div>
      </nav>
    </div>
    <div class="flex justify-end flex-1">
      <div class="flex items-center justify-end gap-2 flex-1">
        <app-activity-icon [orgId]="product.orgId" />
        <app-theme-switcher />
        <app-user-orgs-menu />
      </div>
    </div>
  </header>