import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { USER_MANAGEMENT_ROUTE } from '../app.constants';
import { MainContentSidenavComponent } from "../../views/main-content-sidenav/main-content-sidenav.component";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  private currentRoute: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() {
  }

  getRoute() {
    return this.currentRoute.value;
  }

  setRoute(route) {
    this.currentRoute.next(route.url);
  }

  replaceProduct(productId): string[] {
    if (this.currentRoute.value.includes(USER_MANAGEMENT_ROUTE)) {
      this.setRoute({ url: '/products' });
      return [];
    }
    ;
    const url = this.currentRoute.value.replace(/[0-9]+/, productId);
    const split = url.split('/');
    // detect if we are viewing a scenario
    const pathIds = url.match(/\d{1,}/g);
    if (this._findTotalCount(url) === 0) {
      return ['/products', productId];
    } else if (pathIds.length > 1) {
      // if we are viewing a scenario we need to go back to base product level.
      return split.slice(0, 4);
    }
    return split;
  }

  private _findTotalCount(str) {
    let count = 0;

    for (const ch of str) {
      if (ch >= '0' && ch <= '9') {
        count++;
      }
    }
    return count;
  }

  findParent(activatedRoute: ActivatedRoute) {
    const findMainComponentSidenav = (route) => {
      if (route.component && route.component === MainContentSidenavComponent) {
        return route;
      }
      return findMainComponentSidenav(route.parent);
    };
    return findMainComponentSidenav(activatedRoute.parent)
  }
}
